<template>
  <div class="container">
    <div class="tools">
      <el-form :inline="true" :model="searchForm" class="tools-search" label-width="90px">
        <el-form-item label="礼品：">
          <el-input
            v-model.trim="searchForm.prizeNoOrName"
            size="small"
            :maxlength="25"
            style="width: 220px"
            placeholder="礼品ID/名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="创建日期：">
          <el-date-picker
            v-model="searchForm.createTime"
            align="right"
            type="date"
            size="small"
            style="width: 220px"
            value-format="yyyy-MM-dd"
            placeholder="请选择创建日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="礼品状态：">
          <el-select
            v-model="searchForm.isDisable"
            size="small"
            style="width: 220px"
            placeholder="请选择礼品状态"
          >
            <el-option label="已启用" :value="0"></el-option>
            <el-option label="已停用" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="礼品类型：">
          <el-select
            v-model="searchForm.prizeType"
            size="small"
            style="width: 220px"
            placeholder="请选择礼品类型"
          >
            <el-option label="卡券" :value="1"></el-option>
            <el-option label="实物" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSearch">查询</el-button>
          <el-button
            size="small"
            icon="el-icon-refresh-left
"
            @click="onReset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="tools__add">
      <el-button size="small" type="primary" icon="el-icon-plus" @click="onAddAward"
        >新增</el-button
      >
      <el-button size="small" icon="el-icon-delete" @click="onDelAll">删除</el-button>
    </div>
    <el-table
      ref="multipleTable"
      :data="dataList"
      border
      :max-height="803"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55"
        :selectable="(e) => e.isDisable === 1"
      ></el-table-column>
      <el-table-column label="序号" width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="prizeNo" label="礼品ID" width="100px"></el-table-column>
      <el-table-column label="礼品图" align="center" width="80px">
        <template slot-scope="scope">
          <el-image
            :preview-src-list="[scope.row.prizeUrl]"
            style="width: 50px; height: 50px"
            :src="scope.row.prizeUrl"
            fit="fill"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="prizeName" label="礼品名称" width="200px"> </el-table-column>
      <el-table-column prop="type" label="礼品类型" width="100px">
        <template slot-scope="scope">
          <span>{{ scope.row.prizeType == 0 ? '实物' : '卡券' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="prizeTotal" label="礼品总量"> </el-table-column>
      <el-table-column prop="hairTotal" label="已发放"> </el-table-column>
      <el-table-column prop="userName" label="创建人" width="80px"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="160px"> </el-table-column>
      <el-table-column prop="activityNum" label="关联活动数" width="100px" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            style="width: 69px"
            :disabled="!scope.row.activityNum"
            @click="scope.row.activityNum ? openActivityList(scope.row.activityList) : () => {}"
            >{{ scope.row.activityNum }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="status" label="当前状态" width="80px" fixed="right">
        <template slot-scope="scope">
          <el-link
            style="cursor: text"
            :underline="false"
            :type="scope.row.isDisable === 0 ? 'success' : 'info'"
            >{{ scope.row.isDisable === 0 ? '已启用' : '已停用' }}</el-link
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120px" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="changeDisable(scope.row)">{{
            scope.row.isDisable === 0 ? '停用' : '启用'
          }}</el-button>
          <el-button
            type="text"
            size="small"
            :disabled="scope.row.isDisable !== 1"
            @click="handleClick(scope.row)"
            >编辑</el-button
          >
          <el-button
            :disabled="scope.row.isDisable !== 1"
            type="text"
            size="small"
            @click="handleDel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="page.current"
      :page-size="page.size"
      background
      :page-sizes="[10, 20, 30, 40]"
      layout="prev, pager, next, sizes, jumper"
      :total="page.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>

    <el-dialog
      :title="addAwardForm.prizeId ? '编辑礼品' : '新增礼品'"
      :visible.sync="addAwardVisible"
      :close-on-click-modal="false"
      width="640px"
      z-index="1000"
      @close="onClose"
    >
      <el-form ref="addAwardForm" :model="addAwardForm" :inline="true" class="add-award">
        <el-form-item
          label="礼品名称："
          prop="prizeName"
          label-width="100px"
          :rules="[{ required: true, message: '请填写礼品名称' }]"
        >
          <el-input
            v-model.trim="addAwardForm.prizeName"
            size="small"
            :maxlength="25"
            placeholder="请填写礼品名称"
            style="width: 368px"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="礼品总量："
          prop="prizeTotal"
          label-width="100px"
          :rules="[{ required: true, message: '请填写礼品总量' }, { validator: prizeTotal }]"
        >
          <el-input
            v-model.number.trim="addAwardForm.prizeTotal"
            size="small"
            placeholder="请填写礼品总量"
            :maxlength="25"
            style="width: 368px"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="礼品类型："
          label-width="100px"
          prop="prizeType"
          :rules="[{ required: true, message: '请选择礼品类型', trigger: 'change' }]"
        >
          <el-select v-model="addAwardForm.prizeType" size="small" placeholder="请选择礼品类型">
            <el-option label="卡券" :value="1"></el-option>
            <el-option label="实物" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="addAwardForm.prizeType == 1"
          label="卡券："
          label-width="100px"
          prop="couponTemplateName"
          :rules="[{ required: true, message: '请选择卡券', trigger: 'change' }]"
        >
          <el-input
            v-model="addAwardForm.couponTemplateName"
            placeholder="点击选择卡券"
            readonly
            size="small"
            @focus="onShowCoupon"
          >
            <template slot="append">
              <span style="cursor: pointer" @click="onShowCoupon">选择卡券</span>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="是否启用：" label-width="100px">
          <el-switch
            v-model="addAwardForm.isDisable"
            :active-value="0"
            :inactive-value="1"
          ></el-switch>
        </el-form-item>
        <el-form-item
          label="礼品图："
          required
          prop="prizeUrl"
          label-width="100px"
          :rules="[
            {
              required: true,
              message: '请上传礼品图',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <div class="add-award-upload">
            <uploadImg
              :files="addAwardForm.prizeUrl"
              :multiple="false"
              @upload="uploadImageChange"
            ></uploadImg>
            <!-- <uploadImg
              :imageUrl.sync="addAwardForm.prizeUrl"
              @update:imageUrl="uploadImageChange"
              size="10"
              accept=".jpg,.png"
            ></uploadImg> -->
            <div class="add-award-upload__tips">
              <div>仅支持jpg/png格式，</div>
              <div>大小10MB以内</div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="addAwardVisible = false">取 消</el-button>
        <el-button type="primary" size="small" :loading="submitLoding" @click="onConfirm"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="选择卡券" width="850px" :visible.sync="dialogTableVisible">
      <el-table
        ref="couponRef"
        height="480"
        :data="couponData"
        @select="handleCouponSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column fixed property="couponName" label="名称"></el-table-column>
        <el-table-column property="couponKind" label="卡券种类" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.couponKind == 1 ? '优惠券' : '产品券' }}</span>
          </template>
        </el-table-column>
        <el-table-column property="couponType" label="卡券类型" width="100">
          <template slot-scope="scope">
            <span>{{
              scope.row.couponType == 1
                ? '立减券'
                : scope.row.couponType == 2
                ? '满减券'
                : scope.row.couponType == 3
                ? '折扣券'
                : '--'
            }}</span>
          </template>
        </el-table-column>
        <el-table-column property="productScope" label="产品范围" width="150">
          <template slot-scope="scope">
            <span>{{
              productScopeTypeArr[scope.row.productScopeType] +
              (scope.row.productScope == 2 ? '套系产品' : '基础产品')
            }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="couponPage.pageNumber"
        :page-size="couponPage.pageSize"
        background
        :page-sizes="[10, 20, 30, 40]"
        layout="prev, pager, next, sizes, jumper"
        :total="couponPage.total"
        @size-change="handleCouponSizeChange"
        @current-change="handleCouponCurrentChange"
      >
      </el-pagination>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" size="small" @click="dialogTableVisible = false">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="查看关联活动" width="600px" :visible.sync="showActivity">
      <el-table ref="couponRef" max-height="600" border :data="activityList">
        <el-table-column property="couponName" label="活动形式" width="100">
          <template slot-scope="scope">
            {{ scope.row.activityType === 0 ? '满赠' : '盲盒' }}
          </template>
        </el-table-column>
        <el-table-column property="activityNo" label="活动ID" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="goActivity(scope.row.activityId, scope.row.activityType)"
              >{{ scope.row.activityNo }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column property="activityName" label="活动名称"> </el-table-column>
        <el-table-column property="isDisable" label="活动状态" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.isDisable == 0 ? '已启用' : '已停用' }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
// import uploadImg from "@/components/uploadImg/index.vue";
import uploadImg from '@/components/ty-upload-image/index.vue'
import {
  getAwardInfo,
  saveAwardInfo,
  delAwardInfo,
  delAwardInfos,
  enablePrize,
  getAwardInfoDetail,
} from '@/api/blindBox.js'
import { pageListCouponTemplate } from '@/api/card.js'
import request from '@/mixins/request.js'
export default {
  components: { uploadImg },
  mixins: [request],
  data() {
    return {
      addAwardVisible: false,
      dialogTableVisible: false,
      addAwardForm: {
        prizeName: '',
        prizeTotal: 1,
        isDisable: 0,
        couponTemplateId: '',
        couponTemplateName: '',
        prizeType: 0,
      }, // 新增礼品表单
      submitLoding: false,
      selectionData: [], // 选中数据
      prizeTotal: (rule, value, callback) => {
        if (!/^\+?[1-9][0-9]*$/.test(value)) {
          callback(new Error('礼品总量不能小于1'))
        } else {
          callback()
        }
      },
      couponData: [],
      couponPage: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },
      productScopeTypeArr: ['', '全部', '部分'],
      showActivity: false,
      activityList: [],
    }
  },
  mounted() {
    this.getData(getAwardInfo)
  },
  methods: {
    goActivity(activityId) {
      const routeUrl = this.$router.resolve({
        path: '/activity/fullGift/list',
        query: {
          activityId,
        },
      })
      window.open(routeUrl.href, '_blank')
    },
    openActivityList(list) {
      this.activityList = list || []
      this.showActivity = true
    },
    onShowCoupon() {
      this.couponPage.pageNumber === 1
      this.pageListCouponTemplate().then(() => {
        this.dialogTableVisible = true
        this.toggleRowSelection()
      })
    },
    async pageListCouponTemplate() {
      const res = await pageListCouponTemplate({
        data: {
          companyId: -1,
          isDisable: 0,
          isPrize: 1,
        },
        ...this.couponPage,
      })
      console.log(res)

      this.couponData = res.data.content

      this.dialogTableVisible && this.toggleRowSelection()

      this.couponPage.total = +res.data.total
      return res
    },
    toggleRowSelection() {
      const row = this.couponData.find(
        (e) => this.addAwardForm.couponTemplateId === e.couponTemplateId,
      )
      this.$nextTick(() => {
        this.$refs.couponRef.clearSelection()
        row && this.$refs.couponRef.toggleRowSelection(row, true)
      })
    },
    handleCouponSizeChange(val) {
      console.log(val)
      this.couponPage.pageSize = val
      this.pageListCouponTemplate()
    },
    handleCouponCurrentChange(val) {
      console.log(val)
      this.couponPage.pageNumber = val
      this.pageListCouponTemplate()
    },
    handleCouponSelectionChange(e) {
      this.$refs.couponRef.clearSelection()
      e[e.length - 1] && this.$refs.couponRef.toggleRowSelection(e[e.length - 1], true)
      const { couponTemplateId, couponName, quota } = e[e.length - 1] || {}
      if (!this.addAwardForm.prizeId && quota) this.addAwardForm.prizeTotal = quota || 1
      this.addAwardForm.couponTemplateId = couponTemplateId || ''
      this.addAwardForm.couponTemplateName = couponName || ''
    },
    async changeDisable(row) {
      console.log(row.prizeId)
      try {
        await this.$confirm(`确认是否${row.isDisable === 1 ? '启用' : '禁用'}这个礼品`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        const res = await enablePrize({
          data: row.prizeId,
        })
        this.$message.success(`${row.isDisable === 1 ? '启用' : '停用'}成功！`)
        console.log(res)
        this.getData()
      } catch (error) {}
    },
    /**
     * 新增礼品点击事件
     */
    onAddAward() {
      this.addAwardVisible = true
    },
    uploadImageChange(e) {
      console.log('图片上传', e)
      this.$set(this.addAwardForm, 'prizeUrl', e)
      this.$refs.addAwardForm.validateField('prizeUrl') // 对图片重新验证
    },
    /**
     * 选中事件
     */
    handleSelectionChange(e) {
      console.log('选中', e)
      this.selectionData = e
    },
    async confirm() {
      return await this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
    },
    /**
     * 批量删除
     */
    async onDelAll() {
      if (!this.selectionData.length) return this.$message.error('请选择需要删除的数据~')
      if (this.searchForm.isDisable === 0)
        return this.$message.error('选中礼品中存在已启用礼品，无法进行删除~')
      try {
        await this.confirm()
        const data = this.selectionData.map((e) => e.prizeId)
        await delAwardInfos({ data }).then((res) => {
          console.log(res)
          this.getData()
          this.$message.success('删除成功！')
        })
      } catch (error) {}
    },
    isSelectable(e) {
      console.log('是否可选用', e)
      if (e.isDisable === 0) {
        return true
      }
    },
    /**
     * 删除指定数据
     */
    async handleDel(row) {
      if (row.isDisable === 0) return this.$message.error('选中礼品中存在已启用礼品，无法进行删除~')
      try {
        await this.confirm()
        await delAwardInfo({ data: row.prizeId }).then((res) => {
          console.log(res)
          this.getData()
          this.$message.success('删除成功！')
        })
      } catch (error) {}
    },
    /**
     * 弹窗关闭事件
     * 清空弹窗中的表单数据
     * 重置表单验证规则
     */
    onClose() {
      this.addAwardForm = {
        prizeName: '',
        prizeTotal: 1,
        prizeUrl: '',
        isDisable: 0,
        couponTemplateName: '',
        couponTemplateId: '',
        prizeType: 0,
      }
      this.$refs.addAwardForm.resetFields()
    },
    handleClick(row) {
      getAwardInfoDetail({
        data: row.prizeId,
      }).then((res) => {
        this.addAwardForm = res.data
        this.addAwardVisible = true
      })
    },

    onConfirm() {
      console.log(this.addAwardForm)

      this.$refs.addAwardForm.validate((valid) => {
        if (!valid) return
        this.submitLoding = true
        saveAwardInfo({ data: this.addAwardForm })
          .then((res) => {
            console.log(res)
            this.addAwardVisible = false
            this.getData()
            this.$message.success('提交成功！')
          })
          .finally(() => (this.submitLoding = false))
      })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 24px 20px;
  box-sizing: border-box;
}
.tools {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  &-search {
    ::v-deep .el-form-item {
      margin-bottom: 0;
    }
  }
  &__add {
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}
.add-award {
  display: flex;
  flex-direction: column;
  // align-items: center;
  width: 80%;
  margin: 0 auto;
  &-upload {
    width: 368px;
    display: flex;
    &__tips {
      font-size: 13px;
      color: #9a9d9e;
      font-weight: 400;
      line-height: 24px;
      margin-left: 12px;
    }
  }
  ::v-deep .el-form-item {
    margin-right: 0 !important;
  }
}

::v-deep .el-dialog {
  border-radius: 6px;
  overflow: hidden;
}
::v-deep .el-dialog__header {
  background-color: #fff;
  border-bottom: 1px solid #ededed;
}
::v-deep .el-dialog__title {
  font-size: 16px;
  color: #111111;
  font-weight: 400;
  line-height: 1;
}
::v-deep .el-dialog__footer {
  border-top: 1px solid #ededed;
}
::v-deep .cell {
  word-wrap: break-word;
  word-break: break-all;
}
::v-deep .is-disabled {
  line-height: 0;
}
</style>
