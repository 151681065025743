import request from '@/utils/request'

export function departmentTree(data) {
  return request({
    url: '/user-web-api/admin/department/tree',
    method: 'POST',
    data,
  })
}
export function pageListCouponTemplate(data) {
  return request({
    url: '/marketing-web-api/admin/couponTemplate/pageListCouponTemplate',
    method: 'POST',
    data,
  })
}
export function addOrUpdateCouponT(data) {
  return request({
    url: '/marketing-web-api/admin/couponTemplate/addOrUpdateCoupon',
    method: 'POST',
    data,
  })
}

export function findPageList(data) {
  return request({
    url: '/user-web-api/admin/company/findPageList',
    method: 'POST',
    data,
  })
}
export function isDisableOrDelete(data) {
  return request({
    url: '/marketing-web-api/admin/couponTemplate/isDisableOrDelete',
    method: 'POST',
    data,
  })
}
export function productList(data) {
  return request({
    url: '/product-web-api/admin/product/list',
    method: 'POST',
    data,
  })
}
export function productCategory(data) {
  return request({
    url: '/product-web-api/admin/productCategory/list',
    method: 'POST',
    data,
  })
}
export function queryTreeById(data) {
  return request({
    url: '/product-web-api/admin/productCategory/queryTreeById',
    method: 'POST',
    data,
  })
}
export function detailCouponTemplate(data) {
  return request({
    url: '/marketing-web-api/admin/couponTemplate/detailCouponTemplate',
    method: 'POST',
    data,
  })
}
export function findListConditionPage(data) {
  return request({
    url: '/marketing-web-api/admin/templateProduct/findListConditionPage',
    method: 'POST',
    data,
  })
}
export function getCouponList(data) {
  return request({
    url: '/marketing-web-api/admin/coupon/getCouponList',
    method: 'POST',
    data,
  })
}
export function findPromotionUserStat(data) {
  return request({
    url: '/user-web-api/admin/user/info/consumer/findPromotionUserStat',
    method: 'POST',
    data,
  })
}
export function findPromotionUserPageList(params) {
  const data = {
    url: '/user-web-api/admin/user/info/consumer/findPromotionUserPageList',
    method: 'post',
    data: params,
  }
  if (Number(params.data.export) === 1) {
    data.responseType = 'blob'
  }
  return request({
    ...data,
  })
}
export function findDepartmentUserTreeByCondition(data) {
  return request({
    url: '/user-web-api/admin/user/info/platform/findDepartmentUserTreeByCondition',
    method: 'POST',
    data,
  })
}

export function getCouponListExport(params) {
  return request({
    url: '/marketing-web-api/admin/coupon/getCouponListExport',
    method: 'post',
    data: params,
    responseType: 'blob',
  })
}
export function grantCouponListExport(params) {
  return request({
    url: '/marketing-web-api/admin/coupon/grantCouponListExport',
    method: 'post',
    data: params,
    responseType: 'blob',
  })
}
export function createQrCode(params) {
  return request({
    url: '/misc-web-api/wechat/getCompanyMiniCodeQrCode',
    method: 'post',
    data: params,
  })
}
export function getQrCode(params) {
  return request({
    url: '/misc-web-api/admin/share/param/findByQuery',
    method: 'post',
    data: params,
  })
}
